import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';

import Header from './Header';
import Footer from './Footer';
import ChatList from './Chat/ChatList';
import Book from './Book/Book';
import VideoList from './Videos/VideoList';
import BookMark from 'components/BookMark/BookMark';
import Tip from './Tip/Tip';
import { setShowTip } from 'store/videoRoom.slice';
import { IUser } from 'models/videocall.model';
import NoShow from './NoShow';

interface IProps {
  onLeave: () => void;
  remoteUsers: IUser[];
  length: number;
  service?: string;
  classMode?: string;
}

function Layout({ onLeave, remoteUsers, length, service, classMode }: IProps) {
  const refVideo = useRef<HTMLDivElement>(null);
  const refFooter = useRef<HTMLDivElement>(null);
  const refHeader = useRef<HTMLElement>(null);

  const [chatHeight, setChatHeight] = useState(0);
  const [bookHeight, setBookHeight] = useState(0);
  const dispatch = useDispatch();
  const { isZoomIn, showBookMark, showTip } = useSelector(
    (state: RootState) => state.videoRoom,
  );
  const { pages } = useSelector((state: RootState) => state.book);
  const handle = useFullScreenHandle();

  useEffect(() => {
    if (service === 'eggplay') {
      const htmlRef = document.getElementsByTagName('html');
      if (htmlRef.length > 0) {
        htmlRef[0].style.backgroundColor = '#07416c';
      }
    }
  }, [service]);

  useEffect(() => {
    window.addEventListener('load', updateLayout);
    window.addEventListener('resize', updateLayout);
    updateLayout();
    return () => {
      window.removeEventListener('resize', updateLayout);
      window.removeEventListener('load', updateLayout);
    };
  }, []);

  useEffect(() => {
    const windowHeight = window.innerHeight;

    if (refVideo.current && refHeader.current && refFooter.current) {
      const videoHeight = refVideo.current?.clientHeight;
      const headerHeight = refHeader.current?.clientHeight;
      if (isZoomIn) {
        setChatHeight(windowHeight - headerHeight - 45);
      } else {
        setChatHeight(windowHeight - headerHeight - videoHeight - 45);
      }
    }
  }, [isZoomIn]);

  const updateLayout = () => {
    const windowHeight = window.innerHeight;
    if (refVideo.current && refHeader.current && refFooter.current) {
      const videoHeight = refVideo.current?.clientHeight;
      const footerHeight = refFooter.current?.clientHeight;
      const headerHeight = refHeader.current?.clientHeight;
      setBookHeight(windowHeight - headerHeight - footerHeight - 20);
      setChatHeight(windowHeight - headerHeight - videoHeight - 45);
    }
  };

  useEffect(() => {
    updateLayout();
  }, [length]);

  const handleTipModal = () => {
    dispatch(setShowTip(!showTip));
  };
  return (
    <div id='allWrap'>
      <div id='wrap'>
        <div id='contentWrap'>
          <Header
            ref={refHeader}
            onFullScreen={handle.enter}
            onLeave={onLeave}
          />
          <FullScreen handle={handle}>
            <div id='visiContent' className={isZoomIn ? 'expandExe' : ''}>
              {bookHeight && <Book height={bookHeight} />}
              {showBookMark && <BookMark pages={pages} />}
            </div>
            <VideoList ref={refVideo} remoteUsers={remoteUsers} />
            {!isZoomIn && <ChatList height={chatHeight} />}

            <Footer ref={refFooter} />
          </FullScreen>
        </div>
      </div>
      <Tip visible={showTip} closeModal={handleTipModal} />
      {service === 'eggschool' && classMode === 'CLASSROOM' && <NoShow />}
    </div>
  );
}

export default Layout;
