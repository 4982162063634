import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import MyVideo from './MyVideo';
import Button from 'components/UI/Button';
import { RootState } from 'store/rootReducer';
import VideoCallContext from 'contexts/videocall.context';
import { sendCommand, sendSignalUserChanged } from '../../utils/libs';
import { IUser } from 'models/videocall.model';

interface IProps {
  remoteUsers: IUser[];
}
const VideoList = React.forwardRef(
  (props: IProps, ref: React.Ref<HTMLDivElement>) => {
    const { localUser, setLocalUser, setShowSetting, session } = useContext(
      VideoCallContext
    );
    const { remoteUsers } = props;
    const { audioActive, videoActive, publisher, userType } = localUser;
    const videoRoom = useSelector((state: RootState) => state.videoRoom);

    const zoomIn = () => {
      sendCommand(session, {
        command: 'setZoomIn',
        isZoomIn: !videoRoom.isZoomIn,
      });
      // dispatch(setZoomIn(!videoRoom.isZoomIn));
    };

    const onToggleAudio = () => {
      publisher.publishAudio(!audioActive);
      const newUser: IUser = {
        ...localUser,
        audioActive: !audioActive,
      };
      sendSignalUserChanged(session, { audioActive: !audioActive });
      setLocalUser(newUser);
    };
    const onToggleVideo = () => {
      publisher.publishVideo(!videoActive);
      const newUser: IUser = {
        ...localUser,
        videoActive: !videoActive,
      };
      sendSignalUserChanged(session, { videoActive: !videoActive });
      setLocalUser(newUser);
    };
    const onShowSetting = () => {
      setShowSetting(true);
    };

    return (
      <div
        id='vTelephone'
        ref={ref}
        className={videoRoom.isZoomIn ? 'expandExe' : ''}>
        <div className='vInner'>
          <div
            className={videoRoom.isZoomIn ? 'expandBtn on' : 'expandBtn'}
            style={
              videoRoom.isZoomIn
                ? { justifyContent: 'flex-end' }
                : { justifyContent: 'center' }
            }>
            {userType === 'tutor' && (
              <>
                <Button onClick={zoomIn}>
                  {videoRoom.isZoomIn ? (
                    <FullscreenExitIcon
                      color='primary'
                      style={{ fontSize: 35 }}
                    />
                  ) : (
                    <FullscreenIcon color='primary' style={{ fontSize: 20 }} />
                  )}
                </Button>
              </>
            )}
          </div>
          <div className='vBox'>
            {remoteUsers.map((user) => (
              <>
                <MyVideo
                  audioActive={user.audioActive}
                  videoActive={user.videoActive}
                  publisher={user.publisher}
                  me={false}
                  key={user.connectionId}
                  userType={user.userType}
                  userName={user.userName}
                  userID={user.userID}
                />
              </>
            ))}
          </div>
          <div className='vBox'>
            <MyVideo
              publisher={publisher}
              me={true}
              audioActive={audioActive}
              videoActive={videoActive}
              onToggleAudio={onToggleAudio}
              onToggleVideo={onToggleVideo}
              onShowSetting={onShowSetting}
              userType={localUser.userType}
              userName={localUser.userName}
              userID={localUser.userID}
            />
          </div>
        </div>
      </div>
    );
  }
);

export default VideoList;
